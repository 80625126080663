import { template as template_967394f1de1945979153f229b2285eb5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_967394f1de1945979153f229b2285eb5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
