import { template as template_b9598e7a73904cd9a23eb14fe440ee55 } from "@ember/template-compiler";
const FKLabel = template_b9598e7a73904cd9a23eb14fe440ee55(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
