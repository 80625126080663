import { template as template_b7285ff2b2c4402094e5f2e7f3e8a462 } from "@ember/template-compiler";
const FKText = template_b7285ff2b2c4402094e5f2e7f3e8a462(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
